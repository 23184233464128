
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { RouteNames } from '@/constants';
import {
  authenticateSharepoint,
  signOffCaptureFileNames,
  signOff,
} from '@/api/om27.api';
import * as SharepointApi from '@/api/sharepoint.api';
import BlockingTickets from '@/components/BlockingTickets.vue';
import DataGrid from '@/components/DataGrid.vue';
import SignOffGrid from '@/components/SignOffGrid.vue';
import { RpaOpsModule } from '@/store/om27.module';
import {
  EffectiveDate, LogData,
  LogicalBot, LogicalBotStatuses,
  SignOffActions, SignOffData, SignOffEvidenceFile, SignOffFilters,
  UserPreferences
} from '@/types/om27.types';
import { formatDate } from '@/utils/datetime';
import FileUpload from '@gsk-tech/gsk-file-upload/gsk-file-upload';
import { openErrorSnackbar, openSuccessSnackbar, randId } from '@/utils/components';
import { AxiosError } from 'axios';
import { orderBy } from 'lodash';
import pick from 'lodash/pick';
import { ValidationObserver } from 'vee-validate';
import { Promised } from 'vue-promised';
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    GButton,
    ValidatedForm,
    ValidationObserver,
    DataGrid,
    Promised,
    GDialog,
    SignOffGrid,
    BlockingTickets,
  },
})
export default class SignOff extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  get timezone_short(): UserPreferences['timezone_short'] {
    return RpaOpsModule.userPreferences.timezone_short;
  }

  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return RpaOpsModule.logicalBots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  get signOffId() {
    return this.logicalBot?.signOffId || 0;
  }

  get bots(): LogicalBot[] {
    if (this.logicalBot) {
      return [this.logicalBot];
    }

    return [];
  }

  selectedSignOffAction = '';
  effectiveDates: EffectiveDate[] = [];
  priorEffectiveDate = '';
  signOffDate: Date | null = null;
  signOffSupportTeam = '';
  signOffForDescDateFormatted = '';
  isUploadingFiles = false;
  showSubmitProgress = false;
  uploadedPercent = 0;
  uploadingFileCount = 0;
  loading = true

  get SP_MAX_UPLOAD_SIZE_PER_FILE_BYTES(): number {
    return 2e3 * 1e6;
  }

  get selectedBots(): number[] {
    return this.bots.map(bot => bot.logicalBotId);
  }

  get selectedSignOffActionError(): string {
    if (this.effectiveDatesForAction) {
      return this.effectiveDatesForAction.error || '';
    }
    return '';
  }

  get effectiveDatesForAction(): EffectiveDate | undefined {
    return this.effectiveDates.find(
      effectiveDate => effectiveDate.key === this.selectedSignOffAction,
    );
  }

  get signOffActions(): any {
    return SignOffActions;
  }

  get signOffOptionLabels(): Record<string, string> {
    return {
      [SignOffActions.BotFailed]: 'Bot failed',
      [SignOffActions.NoActivity]: 'Bot did not run and was not intended to run',
      [SignOffActions.BotSuccess]: 'Bot was successful',
    };
  }

  get effectiveDateLabels(): Record<string, string> {
    return {
      [SignOffActions.BotFailed]: 'Bot has failed up to this date',
      [SignOffActions.NoActivity]: 'Bot has not run up to this date',
      [SignOffActions.BotSuccess]: 'Bot has completed the run for the “day” at this time',
    };
  }

  get requiredFields(): Record<string, string[]> {
    return {
      [SignOffActions.BotFailed]: ['ticketNumber', 'signOffEffectiveDate', 'evidenceDetails'],
      [SignOffActions.NoActivity]: ['signOffEffectiveDate', 'evidenceDetails'],
      [SignOffActions.BotSuccess]: ['signOffEffectiveDate', 'evidenceType', 'evidenceDetails'],
    };
  }

  get evidenceTypeLabels(): Record<string, string> {
    return {
      'evidence-dashboard': 'Evidence appears on the dashboard',
      'evidence-retention': 'Evidence is stored with retention period',
    };
  }

  get isSubmitAllowed(): boolean {
    const { selectedSignOffAction, form } = this;
    const requiredFields = this.requiredFields[selectedSignOffAction];
    if (!requiredFields) {
      return false;
    }
    const isValid = requiredFields.every(field => !!form[field]);
    return isValid;
  }

  get uploadProgress() {
    if (this.uploadingFileCount <= 0) {
      return 0;
    }
    return Math.floor(this.uploadedPercent / this.uploadingFileCount);
  }

  async spGetFormDigestValue(token: string): Promise<string> {
    const { data } = await SharepointApi.getFormDigestValue(token);
    const { FormDigestValue } = data;
    const formDigestValue = FormDigestValue.substring(0, FormDigestValue.indexOf(','));
    return formDigestValue;
  }

  async spIsFolderExists(token: string, path: string, folderName: string): Promise<boolean> {
    const { data } = await SharepointApi.getFolders(token, path);
    const { value } = data;
    if (Array.isArray(value) && value.length) {
      return value.some((subFolder) => subFolder.Name === folderName);
    }
    return false;
  }

  async spCreateAFolder(token: string, digestValue: string, path: string) {
    await SharepointApi.createFolder(token, digestValue, path);
  }

  async spUploadAFile(
    token: string,
    digestValue: string,
    path: string,
    fileName: string,
    file: File,
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let fileSize = file.size; // bytes
      let chunkSize = 2 * 1024e3; // bytes

      if (fileSize < chunkSize) {
        // we don't need to split it to chunks, just send the file with single request
        await SharepointApi.addFile(token, digestValue, path, fileName, await file.arrayBuffer());
        this.uploadedPercent += 100;
        resolve(true);
        return;
      }

      let offset = 0;
      let chunksLeft = Math.ceil(fileSize / chunkSize);
      const totalChunkCount = chunksLeft;
      const uploadId = uuidv4();

      // create a placeholder for the file before the upload, since 'startUpload' does not create
      // a file if it doesnt exists.
      await SharepointApi.addFile(token, digestValue, path, fileName, new ArrayBuffer(0));

      const chunkReaderBlock = (offset: number, length: number, file: File) => {
        var r = new FileReader();
        var blob = file.slice(offset, offset + length);
        r.onload = readEventHandler;
        r.readAsArrayBuffer(blob);
      }

      const readEventHandler = async (event: ProgressEvent<FileReader>) => {
        if (!event.target?.error) {
          const buffer = event.target?.result as ArrayBuffer;
          if (--chunksLeft > 0) {
            if (offset === 0) {
              await SharepointApi.startUpload(token, digestValue, path, fileName, buffer, uploadId);
            } else {
              await SharepointApi.continueUpload(token, digestValue, path, fileName, buffer, uploadId, offset);
            }
          } else {
            await SharepointApi.finishUpload(token, digestValue, path, fileName, buffer, uploadId, offset);
          }
          offset += buffer.byteLength;
        } else {
          reject(event.target.error)
        }

        if (offset < fileSize) {
          this.uploadedPercent += 100 / totalChunkCount;
          chunkReaderBlock(offset, chunkSize, file);
        } else {
          resolve(true);
        }
      }

      // start reading chunks
      chunkReaderBlock(offset, chunkSize, file);
    });
  }

  async uploadEvidenceFiles(token: string): Promise<SignOffEvidenceFile[] | string> {
    const filePicker = this.$refs.evidenceFilePicker as FileUpload;
    return new Promise((resolve, reject) => {
      this.$nextTick().then(() => {
        filePicker.updateComplete.then(async () => {
          const files = filePicker.files;
          if (!files || files.length === 0) {
            return resolve([]);
          }
          const { SP_MAX_UPLOAD_SIZE_PER_FILE_BYTES } = this;
          if (files.some(file => file.size > SP_MAX_UPLOAD_SIZE_PER_FILE_BYTES)) {
            const mbs = (SP_MAX_UPLOAD_SIZE_PER_FILE_BYTES / 1e6).toFixed(1);
            return resolve(
              `Max evidence file size must be ${mbs}MB per file. Please check files you have selected.`,
            );
          }
          const { signOffDate, signOffSupportTeam, signOffId, logicalBot } = this;
          if (!signOffDate || !signOffSupportTeam || !logicalBot?.botName) {
            return reject('One of following is missing: ' + JSON.stringify({
              signOffDate,
              signOffSupportTeam,
              'logicalBot.botName': logicalBot?.botName,
            }, null, 2) + ' File upload failed.');
          }
          const day = ('0' + signOffDate.getDate().toString()).slice(-2);
          const month = ('0' + (signOffDate.getMonth() + 1).toString()).slice(-2);
          const monthName = signOffDate.toLocaleString('default', { month: 'long' });
          const year = signOffDate.getFullYear();

          const teamFolder = signOffSupportTeam;
          const monthFolder = `${monthName} ${year}`;
          const dayFolder = `${day}.${month}.${year}`;
          const botFolder = logicalBot?.botName;

          const isFolderExists = await this.spIsFolderExists(
            token,
            `/${teamFolder}/${monthFolder}/${dayFolder}`,
            botFolder,
          );

          const digestValue = await this.spGetFormDigestValue(token);
          if (!isFolderExists) {
            const folders = [teamFolder, monthFolder, dayFolder, botFolder];
            for (let i = 0; i < folders.length; i += 1) {
              const path = folders.slice(0, i + 1).join('/');
              await this.spCreateAFolder(token, digestValue, '/' + path);
            }
          }

          const uploadFolder = `/${teamFolder}/${monthFolder}/${dayFolder}/${botFolder}`;
          const uploadedFiles = [];
          this.uploadingFileCount = files.length;
          for (let i = 0; i < files.length; i += 1) {
            const file = files[i];
            const fileNameSplitted = file.name.split('.');
            const name = fileNameSplitted.slice(0, fileNameSplitted.length - 1).join('');
            const extension = fileNameSplitted.pop();
            const fileName = `${signOffId}-${name}-${randId()}.${extension}`;
            try {
              await this.spUploadAFile(token, digestValue, uploadFolder, fileName, file);
              uploadedFiles.push({
                fileName,
                filePath: uploadFolder,
              });
            } catch (ex: any) {
              if (ex.response?.status === 400) {
                return reject(new Error(
                  'File upload failed. File with same name is already exists on folder.',
                ));
              }
              console.error(ex);
              return reject(new Error(
                `File upload failed due to an unexpected error. Please upload manually.`,
              ));
            }
          }
          resolve(uploadedFiles);
        });
      });
    });
  }

  formatDate(date: string): string {
    return formatDate(date, this.timezone, {
      timeOnly: false,
      dateOnly: false,
      format: 'MMM DD YYYY; HH:mm A zzz',
      timezoneAbbrv: this.timezone_short,
    });
  }

  getSubmissionError(error: AxiosError): string {
    if (error?.response?.data?.response) {
      return `Error: Request failed! ${error.response.data.response}`;
    }
    console.log(error, "error")
    return error.message;
  }

  evidenceTemplate = '';

  get evidenceTemplateRows(): void | number {
    const rows = this.evidenceTemplate.split(/(\r?\n)/gi).length;
    return Math.max(rows, 4);
  }

  get isNotFailed() {
    return this.selectedSignOffAction !== this.signOffActions.BotFailed;
  }

  onSignOffActionChange(e: CustomEvent) {
    const { value } = e.detail;
    this.selectedSignOffAction = value;
    this.form = this.formDefaults();
    if (this.isNotFailed) {
      this.$set(this.form, 'evidenceDetails', this.evidenceTemplate);
    }
  }

  onEffectiveDateChange(e: CustomEvent) {
    const { value } = e.detail;
    this.form.signOffEffectiveDate = value;
  }

  onTicketNumberChange(e: CustomEvent) {
    const { value } = e.detail;
    this.form.ticketNumber = value;
  }

  onEvidenceDetailInput(e: InputEvent) {
    const { target } = e;
    this.form.evidenceDetails = (target as HTMLInputElement).value;
  }

  onEvidenceTypeChanged(e: CustomEvent) {
    const { value } = e.detail;
    this.form.evidenceType = value;
  }

  statusBadgeType(status: string) {
    return LogicalBotStatuses[status as keyof typeof LogicalBotStatuses];
  }

  sortDirs: [string[], string[]] | [] = [];
  sort(rows: LogData[]) {
    return orderBy(rows, ...this.sortDirs);
  }
  filter(rows: LogData[]) {
    return rows
      .filter(row => {
        const { fileName: target } = this.activeFilters;
        if (target.length) {
          return target.includes(row.fileName);
        }
        return true;
      })
      .filter(row => {
        const { machineNameShort: target } = this.activeFilters;
        if (target.length) {
          return target.includes(row.fileName);
        }
        return true;
      });
  }

  formDefaults(): Record<string, string> {
    return {
      evidenceType: 'evidence-dashboard',
      evidenceDetails: '',
      ticketNumber: '',
      signOffEffectiveDate: '',
    };
  }

  form = this.formDefaults();
  submitPromise: Promise<unknown> = Promise.resolve();
  async submitFormData(): Promise<void> {
    this.showSubmitProgress = true;
    const { selectedSignOffAction, form, requiredFields, signOffId } = this;
    const requestData = pick(form, requiredFields[selectedSignOffAction]) as Record<
      'ticketNumber' | 'signOffEffectiveDate' | 'evidenceDetails',
      string
    >;

    let evidenceType = '';
    switch (selectedSignOffAction) {
      case SignOffActions.BotSuccess:
        evidenceType = this.evidenceTypeLabels[form.evidenceType];
        break;

      case SignOffActions.BotFailed:
        evidenceType = 'Bot Failed';
        break;

      case SignOffActions.NoActivity:
        evidenceType = 'Bot did not run and was not intended to run';
        break;
    }

    if (form.evidenceType === 'evidence-retention') {
      this.isUploadingFiles = true;
      let uploadedFilesResult: SignOffEvidenceFile[] | string = [];
      try {
        const { data: spAccessToken } = await authenticateSharepoint();
        uploadedFilesResult = await this.uploadEvidenceFiles(spAccessToken);
        if (typeof uploadedFilesResult === 'string') {
          openErrorSnackbar.call(this, uploadedFilesResult);
          this.isUploadingFiles = false;
          this.showSubmitProgress = false;
          return;
        }
      } catch (ex: any) {
        console.error(ex);
        this.isUploadingFiles = false;
        openErrorSnackbar.call(this, ex.message);
      }

      if (Array.isArray(uploadedFilesResult) && uploadedFilesResult.length > 0) {
        try {
          await signOffCaptureFileNames(signOffId, uploadedFilesResult);
        } catch (ex: any) {
          console.error(ex);
          this.isUploadingFiles = false;
          openErrorSnackbar.call(this, `Couldn't send file information to server. `, ex.message);
        }
      }
    }

    this.submitPromise = signOff({
      ...requestData,
      evidenceType,
      logicalBotId: this.logicalBotId,
      signOffId,
    });

    this.submitPromise
    .then(() => {
      openSuccessSnackbar.call(this, 'You have successfully signed off on a bot');
      this.goToDashboard();
    })
    .catch(() => {
      this.isUploadingFiles = false;
      this.showSubmitProgress = false;
      this.uploadingFileCount = 0;
      this.uploadedPercent = 0;
    });
  }

  goToDashboard(): void {
    this.$router
      .replace({
        name: RouteNames.Om27,
        query: this.$route.query || {
          expand: this.logicalBotId.toString(),
        },
      })
      .catch(() => void 0);
  }

  filters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };
  activeFilters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };

  parseSignOffDate(str: string): Date | null {
    const y = Number(str.substring(0, 4));
    const m = Number(str.substring(4, 6)) - 1;
    const d = Number(str.substring(6, 8));

    const date = new Date(y, m, d);
    return String(date) !== 'Invalid Date' ? date : null;
  }

  promise: Promise<unknown> | null = null;
  signOffPromise: Promise<SignOffData> | null = null;
  async created(): Promise<void> {
    if (!RpaOpsModule.logicalBots.length) {
      this.promise = RpaOpsModule.getDashboard();
    } else {
      this.promise = Promise.resolve();
    }

    await this.promise;

    if (!this.logicalBot) {
      this.goToDashboard();
    }

    const signOffId = this.logicalBot?.signOffId as number;

    this.effectiveDates = await RpaOpsModule.getSignOffEffectiveDates(signOffId);
    this.signOffPromise = RpaOpsModule.getSignOffData(signOffId);
    this.signOffPromise
      .then(data => {
        this.priorEffectiveDate = data.priorEffectiveDate;
        this.signOffForDescDateFormatted = data.signofffordesc;
        this.signOffDate = this.parseSignOffDate(String(data.signofffor));
        this.signOffSupportTeam = data.supportTeam;
        this.evidenceTemplate = data.evidenceTemplate;
        return data;
      })
      .then(data => {
        // get filters
        const targets = data.logData
          .map(d => {
            const { fileName, machineNameShort } = d;
            return {
              fileName,
              machineNameShort,
            };
          })
          .reduce(
            (acc, obj) => {
              acc.fileName.add(obj.fileName);
              acc.machineNameShort.add(obj.machineNameShort);
              return acc;
            },
            { fileName: new Set() as Set<string>, machineNameShort: new Set() as Set<string> },
          );
        this.filters.machineNameShort = [...targets.machineNameShort];
        this.filters.fileName = [...targets.fileName];
      })
      .finally(() =>{
        this.loading = false
      })
  }
}
